import SectionWrapper, {
  TermsText,
  TermsWrapper
} from "./termsConditions.style"

import React from "react"
import Fade from "react-reveal/Fade"
import SectionHeading from "../../components/SectionHeading"
import Container from "../../components/UI/Container"

const PrivacyPage = () => {
  return (
    <SectionWrapper>
      <Container noGutter>
        <Fade up delay={200}>
          <SectionHeading mb="64px" title="Terms & Conditions" as="h1" />
        </Fade>
        <TermsWrapper>
          <Fade up delay={200}>
            <TermsText>
              <h2>Industry and Government Regulations</h2>
              <p>
                At Elegant Clinic we follow all guidelines set out by the GPHC.
              </p>
              <h2>Payment Methods</h2>
              <p>
                At Elegant Clinic we accept credit/debit card and cash payments
                plus bank transfer. Payments are taken once treatment is
                complete.
              </p>
              <h2>Consultations and Deposits</h2>
              <p>
                The charge for a consultation is £30 with any member of our team
                which we ask to be paid at the time of booking.
              </p>
              <p>
                For any online bookings a £30 deposit charge is taken online to
                secure your appointment. This will be deducted off your
                treatment cost in clinic.
              </p>
              <p>
                Following your consultation, should you proceed with any
                treatment, this fee will be deducted off your treatment cost.
              </p>
              <p>
                Should you be unsuitable for treatment for any reason, this will
                also be refunded to you following your consultation.
              </p>
              <p>
                We accept all credit and debit card payments. We also send you a
                text reminder 24 hours in advance to remind you of your
                appointment date and time.
              </p>
              <h2>Cancellation policy</h2>
              <p>
                If you need to cancel or reschedule your appointment for any of
                our clinics, we ask for 48 hours’ notice. To cancel or
                reschedule please text our booking line on 01202 069884 or email us at <a href="mailto: bookings@elegantclinic.co.uk">
                bookings@elegantclinic.co.uk
                  </a>.
              </p>
              <p>
                If you do cancel or reschedule in less than the required time,
                or do not show for your appointment, your deposit will be
                retained as we are unable to offer that time to another patient.
                The cancellation fee is not refundable or transferable and is
                due in full at the time of booking the appointment.
              </p>
              <h2>Treatment Suitability</h2>
              <p>
                We will always assess whether a treatment is suitable for you,
                or likely to be successful, prior to any treatment being carried
                out.
              </p>
              <p>
                If a treatment is not suitable or likely to be successful, we
                will explain the reasons why.
              </p>
              <p>
                If we feel a treatment is not suitable for you, we will not
                charge you for the consultation and your deposit will be
                refunded.
              </p>
              <p>
                We will also not go ahead with a procedure if we feel that your
                expectations exceed the results that can be achieved through
                that treatment.
              </p>
              <h2>Clinic Offers</h2>
              <p>
                Please note that offers must be redeemed at the time of booking
                and purchase and cannot be claimed retrospectively.
              </p>
              <p>We reserve the right to end an offer at any time.</p>
              <p>
                Offers are non-exchangeable and cannot be redeemed for another
                treatment or monetary value.
              </p>
              <h2>Complaints</h2>
              <p>
                If you do have a complaint or wish to contact us, please email
                teagan@elegantclinic.co.uk and we will respond asap.
              </p>
            </TermsText>
          </Fade>
        </TermsWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default PrivacyPage
