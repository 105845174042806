import React from "react"

import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import TermsAndConditions from "../containers/TermsConditions"
import { ContentWrapper } from "../containers/elegant.style"

const TermsAndConditionsPage = () => {
  return (
    <>
      <SEO
        title="Elegant Privacy Policy"
        shortDesc="Terms and Conditions"
        description="Terms and Conditions"
      />
      <Layout>
        <ContentWrapper>
          <TermsAndConditions />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default TermsAndConditionsPage
